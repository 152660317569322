<template>
    <van-form
        ref="form"
        error-message-align="right"
        input-align="right"
        :readonly="!!nodeData.id"
        :show-error="false"
        @submit="onSubmit"
    >
        <van-cell-group inset>
            <van-field
                is-link
                required
                readonly
                label="类型"
                :value="getNodeName(nodeData.type)"
                :rules="[{ required: true, message: '请选择类型' }]"
                @click="nodeData.id ? '' : (show = true)"
            />

            <van-field
                v-model="nodeData.remarks"
                :autosize="{ maxHeight: 300, minHeight: 150 }"
                required
                label="备注"
                class="my-textarea"
                maxlength="300"
                show-word-limit
                type="textarea"
                placeholder="备注"
                :rules="[{ required: true, message: '请填写备注' }]"
            />
            <van-action-sheet
                v-model="show"
                :actions="importantNodeType"
                close-on-click-action
                :close-on-click-overlay="true"
                cancel-text="取消"
                @select="nodeData.type = $event.code"
            />
        </van-cell-group>

        <div class="btn-group">
            <van-button color="var(--secondMainColor)" native-type="button" @click="goBack">
                返回
            </van-button>
            <van-button
                v-if="!nodeData.id"
                color="var(--mainColor)"
                native-type="submit"
                :loading="submitting"
            >
                提交
            </van-button>
        </div>
    </van-form>
</template>

<script>
import { saveNode } from "@/api/hoss";
export default {
    components: {},
    props: {},
    data() {
        return {
            nodeData: {
                id: "",
                type: "",
                remarks: "",
            },
            importantNodeType: [],
            show: false,
            submitting: false,
        };
    },
    async created() {
        const { importantNodeType } = await this.$getDict();
        this.importantNodeType = importantNodeType.children;

        const { id } = this.$route.query;
        if (id) {
            const data = localStorage.getItem("ImportantNode_" + id);
            this.nodeData = JSON.parse(data);
        }
    },
    methods: {
        getNodeName(code) {
            if (!code || !this.importantNodeType.length) return "";
            const item = this.importantNodeType.find((item) => item.code == code);
            return item ? item.name : "";
        },
        goBack() {
            this.$router.back(-1);
        },
        async onSubmit() {
            this.submitting = true;
            const { clientId, stage } = this.$route.query;
            await saveNode({ ...this.nodeData, stage, clientId });
            this.submitting = false;
            this.goBack();
        },
    },
};
</script>

<style scoped lang="less">
.van-form {
    margin-bottom: 54px;
    .van-cell-group {
        margin: 10px;
        border-radius: 10px;
    }
    .btn-group {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        .van-button {
            flex: 1 0 50%;
            border-radius: 0;
        }
    }
}
</style>
