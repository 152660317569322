import request from "../request";

// 未完成小红点
export function listUncompleted(clientId) {
    return request({
        method: "post",
        url: `/client/recover/listUncompleted`,
        data: {clientId}
    });
}

// 客户列表
export function getClientList(data) {
    return request({
        method: "json",
        url: `/client/info/list`,
        data,
    });
}

// 新版客户列表
export function queryAndFilterMyClients(data) {
    return request({
        method: "json",
        url: `/client/info/myClients`,
        data,
    });
}

// 传入客户id列表，获取客户详情
export function getClientInfoListByClientIds(data) {
    return request({
        method: "json",
        url: `/client/info/listByIds`,
        data,
    });
}

// 阶段和状态menu 接口
export function getSearchMenu() {
    return request({
        method: "get",
        url: `/client/info/searchMenus`,
    });
}

// 提交破冰记录
export function saveIceRes(data) {
    return request({
        method: "json",
        url: `/client/iceResult/save`,
        data,
    });
}

// 提交破冰记录
export function queryIceRes(id) {
    return request({
        method: "json",
        url: `/client/iceResult/detail?id=${id}`,
    });
}

// 破冰记录列表
export function iceResList({clientId, page, pageSize = 10}) {
    return request({
        method: "get",
        url: `/client/iceResult/list?clientId=${clientId}&pageNum=${page}&pageSize=${pageSize}`,
    });
}

// 实施记录列表
export function recordList({clientId, page, pageSize = 10}) {
    return request({
        method: "get",
        url: `/client/record/list?clientId=${clientId}&pageNum=${page}&pageSize=${pageSize}`,
    });
}

// 实施记录新列表-分组
export function queryRecordListGrouped({clientId}) {
    return request({
        method: "get",
        url: `/client/record/group?clientId=${clientId}`,
    });
}

// 粉丝群报告列表
export function fanGroupReportList({clientId, page, pageSize = 10}) {
    return request({
        method: "get",
        url: `/client/fanGroupReport/list?clientId=${clientId}&pageNum=${page}&pageSize=${pageSize}`,
    });
}

// 粉丝群报告分组
export function fanGroupReportGroup({clientId}) {
    return request({
        method: "get",
        url: `/client/fanGroupReport/group?clientId=${clientId}`,
    });
}

// 复盘总结列表
export function reviewSummaryList({clientId, page, pageSize = 10}) {
    return request({
        method: "get",
        url: `/client/reviewSummary/list?clientId=${clientId}&pageNum=${page}&pageSize=${pageSize}`,
    });
}

// 查询客户基本信息
export function queryClientDetail(clientId) {
    return request({
        method: "get",
        url: `/client/extend/detailInfo?clientId=${clientId}`,
    });
}

// 查询客户阶段信息
export function queryClientStage(clientId) {
    return request({
        method: "get",
        url: `/client/stage/detail?clientId=${clientId}`,
    });
}

// 查询客户阶段信息-新
export function queryClientStageChart(clientId) {
    return request({
        method: "get",
        url: `/client/stage/chart?clientId=${clientId}`,
    });
}

// 破冰转一阶段
export function iceToOneStage(clientId) {
    return request({
        method: "get",
        url: `/client/stage/toStageOne?clientId=${clientId}`,
    });
}

// 查询实施总结详情
export function getResultDetail({milepostId = "", id = "", date = null}) {
    return request({
        method: "post",
        url: date !== null ? `/client/record/detail?milepostId=${milepostId}&id=${id}&date=${date}` :
            `/client/record/detail?milepostId=${milepostId}&id=${id}`,
    });
}

// 保存实施总结详情
export function saveResultDetail(data) {
    return request({
        method: "json",
        url: `/client/record/save`,
        data,
    });
}

// 查询复盘总结详情
export function queryReviewSummary(id = "") {
    return request({
        method: "post",
        url: `/client/reviewSummary/detail?id=${id}`,
    });
}

// 新版查询复盘总结详情、创建和详情都是这个接口
export function queryReviewSummaryGetOrCreate(id = "", milepostId = "", execDate = "") {
    return request({
        method: "post",
        url: `/client/reviewSummary/getOrCreate?id=${id}&milepostId=${milepostId}&execDate=${execDate}`,
    });
}

// 保存复盘总结详情
export function saveReviewSummary(data) {
    return request({
        method: "json",
        url: `/client/reviewSummary/save`,
        data,
    });
}

// 保存粉丝群报告
export function saveFanGroupReport(data) {
    return request({
        method: "json",
        url: `/client/fanGroupReport/save`,
        data,
    });
}

// 查询粉丝群报告
export function queryFanGroupReport(id = "", milepostId = "") {
    return request({
        method: "post",
        url: `/client/fanGroupReport/detail?id=${id}&milepostId=${milepostId}`,
    });
}

// 提交或审核申请 item 对应end(终止),changeStage(转阶段),statement(结单)
export function saveApprovalDetail(data) {
    return request({
        method: "json",
        url: `client/approval/save`,
        data,
    });
}

// 查询申请详情 item 对应end(终止),changeStage(转阶段),statement(结单)
export function getApproveDetail({id, item}) {
    return request({
        method: "post",
        url: `client/approve/detail`,
        data: {id, item},
    });
}

// 新版查询申请详情
export function getApprovalDetail(id) {
    return request({
        method: 'get',
        url: `client/approval/detail?applicationId=${id}`,
        // data: {id},
    });
}

// 查询审批记录
export function getApprovalList({clientId}) {
    return request({
        method: "get",
        url: `client/approval/list?clientId=${clientId}`,
    });
}

// 重要节点
// 更新添加重要节点记录
export function saveNode(data) {
    return request({
        method: "json",
        url: `/client/importantNode/save`,
        data,
    });
}

// 根据客户id查询节点记录
export function getNodeList(clientId) {
    return request({
        method: "get",
        url: `/client/importantNode/selectByClientId?clientId=${clientId}`,
    });
}

// 客户档案 相关
// 当前人未完成创建的客户
export function incompleteCreation(data) {
    return request({
        method: "json",
        url: "/client/info/incompleteCreation",
        data,
    });
}

// 删除客户信息
export function delClient(clientId) {
    return request({
        method: "post",
        url: "/client/info/delete",
        data: {clientId},
    });
}

// 更新添加客户基本信息
export function saveClientInfo(data) {
    return request({
        method: "json",
        url: "/client/info/save",
        data,
    });
}

// 更新添加诊断情况
export function saveDiagnosis(data) {
    return request({
        method: "json",
        url: "/client/extend/saveDiagnosis",
        data,
    });
}

// 更新添加睡眠情况
export function saveSleep(data) {
    return request({
        method: "json",
        url: "/client/extend/saveSleep",
        data,
    });
}

// 更新添加其他情况
export function saveOther(data) {
    return request({
        method: "json",
        url: "/client/extend/saveOther",
        data,
    });
}

// client/info/publish?clientId= Post
export function publishClientInfo(clientId) {
    return request({
        method: "post",
        url: "/client/info/publish?clientId=" + clientId,
    });
}

// 查询客户基本信息
export function getClientInfo(clientId) {
    return request({
        method: "get",
        url: `/client/info/detail?clientId=${clientId}`,
    });
}

// 查询诊断情况
export function getDiagnosis(clientId) {
    return request({
        method: "get",
        url: `/client/extend/detailDiagnosis?clientId=${clientId}`,
    });
}

// 查询睡眠情况
export function getSleep(clientId) {
    return request({
        method: "get",
        url: `/client/extend/detailSleep?clientId=${clientId}`,
    });
}

// 查询其他情况
export function getOther(clientId) {
    return request({
        method: "get",
        url: `/client/extend/detailOther?clientId=${clientId}`,
    });
}

// 查询客户全部情况
export function getClientFile(clientId) {
    return request({
        method: "get",
        url: `/client/extend/clientFile?clientId=${clientId}`,
    });
}

export function saveClientFile(data) {
    return request({
        method: "json",
        url: "/client/extend/saveFile",
        data,
    });
}

// 查询全部药品列表
export function getMedicineList() {
    return request({
        method: "post",
        url: `/medicine_info/listActive`,
    });
}

export function handleTransfer(applicationId, scheduledDate, executionResult) {
    return request({
        method: "post",
        url: `/client/approval/handleTransfer`,
        data: {applicationId, scheduledDateInSecond: scheduledDate, executionResult},
    });
}
